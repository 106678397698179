<template>
<component v-if="isAuthenticated" :is="getLayout" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LayoutMain',
  data() {
    return {
      isLayout: '',
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getRole',
      'isAuthenticated',
    ]),
    getLayout() {
      if (!this.getRole) return undefined

      switch (this.getRole) {
      case 'ROLE_SUPER_ADMIN':
        return () => import('@/view/layout/LayoutAdmin')
      case 'ROLE_BUSINESS_OWNER':
      case 'ROLE_BUSINESS_ADMIN':
        return () => import('@/view/layout/LayoutCompany')
      default:
        return undefined
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({
        name: 'login',
        query: {
          path: this.$route.path,
          ...this.$route.query, 
        }, 
      })
    }
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  methods: {
    onResize() {
      const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      this.$store.commit('setDetectMobile', w)
    },
  },
}
</script>

<style lang="scss">
  svg { pointer-events: none; }
  .w-20{
    width: 20px;
  }
  .scrollbar-primary::-webkit-scrollbar {
    cursor: pointer;
    width: 4px;
    background-color: #f5f5f5;
  }
  .scrollbar-primary::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1);
    background-color: #D5E3E8;
  }
</style>
